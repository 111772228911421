import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";

import { MainWrapper } from "./components/utils";

import "./assets/css/index.css";
import "./assets/css/normalize.css";
import "./assets/cssmodules/global/index.module.css";

const AppComp = React.lazy(() =>
  import("./App").then(({ App }) => ({ default: App }))
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MainWrapper>
      <Suspense>
        <AppComp />
      </Suspense>
    </MainWrapper>
  </React.StrictMode>
);

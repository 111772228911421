import clsx from "clsx";

import styles from "./ProfileButton.module.css";

type Props = {
  text: string;
  layout?: "normal" | "tile";
  onClickFn?: () => void;
};
export const ProfileButton = ({
  text,
  layout = "normal",
  onClickFn = () => {},
}: Props) => (
  <button
    type="button"
    onClick={onClickFn}
    className={clsx(
      layout === "normal" && styles.profileButton,
      layout === "tile" && styles.profileButtonTile
    )}
  >
    {text}
  </button>
);

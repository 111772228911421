import { Variants } from "framer-motion";

export const animationVariants: Variants = {
  initial: (isBackward) => ({
    x: isBackward ? -200 : 200,
    opacity: 0,
  }),
  animate: {
    x: 0,
    opacity: 1,
    transition: { type: "tween", duration: 0.5, ease: "easeOut" },
  },
  exit: (isBackward) => ({
    x: isBackward ? 200 : -200,
    opacity: 0,
    transition: { type: "tween", duration: 0.5, ease: "easeOut" },
  }),
};

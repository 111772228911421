function formDataFn(formData: FormData, data: any, parentKey?: string) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      formDataFn(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey ?? "", value);
  }
}

export const buildFormData = (data: any) => {
  const formData = new FormData();
  formDataFn(formData, data);
  return formData;
};

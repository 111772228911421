import { ErrorBoundary } from "react-error-boundary";

import { ErrorContainer } from "../../containers/ErrorContainer";

type FallbackRenderFnArg = {
  error: Error;
  resetErrorBoundary: (...args: unknown[]) => void;
};

const fallbackRenderFn = ({ error, resetErrorBoundary }: FallbackRenderFnArg) =>
  process.env.REACT_APP_PROD === "0" ? (
    <div role="alert">
      <div>Oh no</div>
      <pre>
        {error.name} - {error.message}
      </pre>
      <button type="button" onClick={() => resetErrorBoundary()}>
        Try again
      </button>
    </div>
  ) : (
    <ErrorContainer resetFn={resetErrorBoundary} />
  );

type Props = { children: JSX.Element };
export const ErrorBoundaryWrapper = ({ children }: Props) => (
  <ErrorBoundary fallbackRender={fallbackRenderFn}>{children}</ErrorBoundary>
);

import axios from "axios";

import { buildFormData } from "../../parsers";

// form-data
export const mainApi = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
  transformRequest: [(data) => buildFormData(data)],
});


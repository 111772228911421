import { useId } from "react";

import styles from "./WithGradientCircles.module.css";

type Props = {
  /**
   * Enter number value - it will be converted to pixels
   */
  circles: {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
    size?: number;
    zIndex?: number;
    blue?: boolean;
  }[];
  children: JSX.Element | JSX.Element[];
};
export const WithGradientCircles = ({ circles, children }: Props) => {
  const id = useId();

  return (
    <div className={styles.container}>
      {circles.map((element, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={`${index}${id}`}
          style={
            {
              top: `${element.top}px`,
              left: `${element.left}px`,
              bottom: `${element.bottom}px`,
              right: `${element.right}px`,
              zIndex: element.zIndex,
              "--size": element.size ? `${element.size}px` : undefined,
              "--color": element.blue ? "rgba(143, 200, 220, 0.2)" : undefined,
            } as React.CSSProperties
          }
          className={styles.gradientCircles}
        />
      ))}
      {children}
    </div>
  );
};

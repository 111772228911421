import clsx from "clsx";
import { ForwardedRef, forwardRef } from "react";

import styles from "./SubHeading.module.css";

type Props = {
  children: string;
  size?: "extraSmall" | "small" | "medium" | "large" | number;
  textAlign?: "left" | "center" | "right";
  usePx?: boolean;
  jakartaFont?: boolean;
  textShadow?: boolean;
  color?: "blue" | "gray";
};
export const SubHeading = forwardRef(
  (
    {
      children,
      size = "small",
      textAlign = "center",
      usePx = false,
      jakartaFont = false,
      textShadow = false,
      color = "gray",
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      style={typeof size === "number" ? { fontSize: `${size}px` } : undefined}
      className={clsx([
        typeof size === "number" && styles.subHeading,
        !usePx && [
          size === "extraSmall" && styles.subHeadingExtraSmall,
          size === "small" && styles.subHeadingSmall,
          size === "medium" && styles.subHeadingMedium,
          size === "large" && styles.subHeadingLarge,
        ],
        usePx && [
          size === "extraSmall" && styles.subHeadingExtraSmallPx,
          size === "small" && styles.subHeadingSmallPx,
          size === "medium" && styles.subHeadingMediumPx,
          size === "large" && styles.subHeadingLargePx,
        ],

        jakartaFont && styles.jakartaFont,

        textAlign === "left" && styles.alignLeft,
        textAlign === "right" && styles.alignRight,

        textShadow && styles.textShadow,

        color === "blue" && styles.blueColor,
      ])}
    >
      {children}
    </div>
  )
);

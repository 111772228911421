import clsx from "clsx";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { useId } from "react";

import { CircleLoader } from "../../loaders";
import { Ripple } from "../../utils";

import styles from "./DefaultButton.module.css";

const animationVariants: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { type: "tween", ease: "easeInOut", duration: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { type: "tween", ease: "easeInOut", duration: 0.2 },
  },
};

type Props = {
  text: string;
  color?: "blue" | "red";
  onClickFn?: () => void;
  submitButton?: boolean;
  isLoading?: boolean;
  addRipple?: boolean;
  addShadow?: boolean;
};
export const DefaultButton = ({
  text,
  color = "blue",
  onClickFn = () => {},
  submitButton = false,
  isLoading = false,
  addRipple = false,
  addShadow = false,
}: Props) => {
  const id = useId();

  return (
    <button
      type={submitButton ? "submit" : "button"}
      className={clsx(
        styles.defaultButton,
        color === "red" && styles.red,
        addShadow && styles.shadow
      )}
      onClick={onClickFn}
      disabled={isLoading}
    >
      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.span
            key={`${id}-loader`}
            className={styles.buttonLoader}
            variants={animationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <CircleLoader />
          </motion.span>
        ) : (
          <motion.span
            key={`${id}-text`}
            className={styles.buttonText}
            variants={animationVariants}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            {text}
          </motion.span>
        )}
      </AnimatePresence>
      {addRipple && <Ripple />}
    </button>
  );
};

import { AnimatePresence } from "framer-motion";
import { Route, Switch, useLocation } from "react-router-dom";

import { ExtendedRoute } from "../../model";
import { AnimatedRoute } from "./AnimatedRoute";

import { ProtectedRoute } from "./ProtectedRoute";

type Props = {
  routes: ExtendedRoute[];
  baseUrl?: string;
  wrapWithSwitch?: boolean;
};
export const RoutesRenderer = ({
  routes,
  baseUrl = "",
  wrapWithSwitch = false,
}: Props) => {
  const location = useLocation();

  const returnRouteComponents = () =>
    routes.map((route) => {
      if (route?.isProtected) {
        return (
          <ProtectedRoute
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...route}
            key={route.path as string}
            path={baseUrl + route.path}
            redirectPathWhenLoggedIn={route?.redirectPathWhenLoggedIn}
          >
            <AnimatedRoute>{route.children}</AnimatedRoute>
          </ProtectedRoute>
        );
      }
      return (
        <Route
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...route}
          key={route.path as string}
          path={baseUrl + route.path}
        >
          <AnimatedRoute>{route.children}</AnimatedRoute>
        </Route>
      );
    });
  if (wrapWithSwitch) {
    return (
      <AnimatePresence>
        <Switch key={location.pathname} location={location}>
          {returnRouteComponents()}
        </Switch>
      </AnimatePresence>
    );
  }
  return <AnimatePresence>{returnRouteComponents()}</AnimatePresence>;
};

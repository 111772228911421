import { useValidateTokenQuery } from "../../../hooks/useValidateTokenQuery";
import {
  useGetAboutUsContent,
  useGetAllCategories,
  useGetContactData,
  useGetLatestAndPopularPodcasts,
  useGetMainSwiperContent,
  useGetUserHistory,
} from "../../../modules/dashboard/hooks/queries";
import { LogoLoader } from "../../loaders";
import { SuspenseWithFlag } from "../SuspenseWithFlag";

type Props = {
  children: JSX.Element;
};
export const PreloadContentWrapper = ({ children }: Props) => {
  const validateToken = useValidateTokenQuery();
  const getAllCategories = useGetAllCategories();
  const getMainSwiperContent = useGetMainSwiperContent();
  const getUserHistory = useGetUserHistory();
  const getLatestAndPopularPodcasts = useGetLatestAndPopularPodcasts();
  const getContactData = useGetContactData();
  const getAboutUsContent = useGetAboutUsContent();

  return (
    <SuspenseWithFlag
      renderAsDisplayNone
      fallback={<LogoLoader />}
      isSuspended={
        validateToken.isFetching ||
        getAllCategories.isLoading ||
        getMainSwiperContent.isLoading ||
        getUserHistory.isLoading ||
        getLatestAndPopularPodcasts.isLoading ||
        getContactData.address.isLoading ||
        getContactData.subjects.isLoading ||
        getAboutUsContent.isLoading
      }
    >
      {children}
    </SuspenseWithFlag>
  );
};

import { DefaultButton } from "../../buttons";
import { MainHeading } from "../../headings";

import personImg from "../../../assets/images/svg/person2-3.svg";
import styles from "./ErrorContainer.module.css";

type Props = { resetFn: () => void };
export const ErrorContainer = ({ resetFn }: Props) => (
  <div className={styles.errorContainer}>
    <div className={styles.errorContainerInner}>
      <img className={styles.img} src={personImg} alt="" />
      <MainHeading size="large">Wystąpił błąd w aplikacji</MainHeading>
      <div className={styles.button}>
        <DefaultButton text="Spróbuj ponownie" onClickFn={resetFn} />
      </div>
    </div>
  </div>
);

import { getTokenCookie } from "../../authUtils";
import { mainApi } from "./client";

mainApi.interceptors.request.use(
  (config) => {
    // get current "token" cookie and attach it to each request made from "mainApi" axios instance
    config.headers!.Authorization = `Bearer ${getTokenCookie()}`;

    return config;
  },
  (error) => Promise.reject(error)
);

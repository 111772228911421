import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { convertPathArrayIntoString } from "../../../utils";

import buttonBack from "../../../assets/images/svg/button-back.svg";
import styles from "./BackButton.module.css";

type Props = { customClickFn?: () => void; renderAsNavigationHelper?: boolean };
export const BackButton = ({
  customClickFn,
  renderAsNavigationHelper = false,
}: Props) => {
  const history = useHistory();

  const returnOnClickFn = () => {
    const stringPath = convertPathArrayIntoString(
      JSON.parse(sessionStorage.getItem("pathArray")!),
      true
    );

    if (customClickFn) {
      customClickFn();
    } else if (Number(sessionStorage.getItem("pathLevel")) === 1) {
      history.push("/");
    } else if (stringPath) {
      history.push(stringPath);
    } else {
      history.goBack();
    }
  };

  return (
    <button
      id={renderAsNavigationHelper ? "BackButton" : undefined}
      type="button"
      className={clsx(
        styles.backButton,
        renderAsNavigationHelper && styles.hidden
      )}
      onClick={returnOnClickFn}
    >
      <img src={buttonBack} alt="Powrót" />
    </button>
  );
};

import { motion, Variants } from "framer-motion";
import { useId } from "react";

import { MainIcon } from "../../icons/MainIcon";

import styles from "./LogoLoader.module.css";

const loadingAnimationVariants: Variants = {
  initial: { opacity: 1 },
  animate: {
    opacity: 0.7,
    transition: {
      type: "tween",
      repeat: Infinity,
      repeatType: "mirror",
      duration: 3,
      delay: 1,
    },
  },
};

export const LogoLoader = () => {
  const id = useId();

  return (
    <motion.div
      key={`${id}-motion`}
      variants={loadingAnimationVariants}
      initial="initial"
      animate="animate"
      className={styles.logoLoader}
    >
      <MainIcon />
    </motion.div>
  );
};

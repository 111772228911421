import clsx from "clsx";
import { ForwardedRef, forwardRef } from "react";

import styles from "./MainHeading.module.css";

type Props = {
  children: string;
  id?: string;
  size?: "extraSmall" | "small" | "medium" | "large" | number;
  textAlign?: "left" | "center" | "right";
  usePx?: boolean;
  font?: "default" | "jakarta";
  textShadow?: boolean;
  wrapAnywhere?: boolean;
};
export const MainHeading = forwardRef(
  (
    {
      children,
      id = undefined,
      size = "small",
      textAlign = "center",
      usePx = false,
      font = "default",
      textShadow = false,
      wrapAnywhere = false,
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      id={id}
      style={typeof size === "number" ? { fontSize: `${size}px` } : undefined}
      className={clsx([
        typeof size === "number" && styles.mainHeading,
        !usePx && [
          size === "extraSmall" && styles.mainHeadingExtraSmall,
          size === "small" && styles.mainHeadingSmall,
          size === "medium" && styles.mainHeadingMedium,
          size === "large" && styles.mainHeadingLarge,
        ],
        usePx && [
          size === "extraSmall" && styles.mainHeadingExtraSmallPx,
          size === "small" && styles.mainHeadingSmallPx,
          size === "medium" && styles.mainHeadingMediumPx,
          size === "large" && styles.mainHeadingLargePx,
        ],
        font === "jakarta" && styles.jakartaFont,
        textAlign === "left" && styles.alignLeft,
        textAlign === "right" && styles.alignRight,
        textShadow && styles.textShadow,
        wrapAnywhere && styles.wrapAnywhere
      ])}
    >
      {children}
    </div>
  )
);

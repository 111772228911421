import { useLayoutEffect, useState } from "react";

import styles from "./Ripple.module.css";

import {
  RippleContainerProps,
  RippleObject,
  RippleProps,
  UseDebouncedRippleCleanUpFn,
} from "./typings";

const RippleContainer = ({ children, onPointerDown }: RippleContainerProps) => (
  <div onPointerDown={onPointerDown} className={styles.rippleContainer}>
    {children}
  </div>
);

const useDebouncedRippleCleanUp: UseDebouncedRippleCleanUpFn = (
  rippleCount,
  duration,
  cleanUpFunction
) => {
  useLayoutEffect(() => {
    let bounce: NodeJS.Timeout | undefined;
    if (rippleCount > 0) {
      clearTimeout(bounce);

      bounce = setTimeout(() => {
        cleanUpFunction();
        clearTimeout(bounce);
      }, duration * 4);
    }

    return () => clearTimeout(bounce);
  }, [rippleCount, duration, cleanUpFunction]);
};

export const Ripple = ({ duration = 850 }: RippleProps) => {
  const [rippleArray, setRippleArray] = useState<RippleObject[]>([]);

  useDebouncedRippleCleanUp(rippleArray.length, duration, () => {
    setRippleArray([]);
  });

  const addRipple = (event: React.PointerEvent<HTMLDivElement>) => {
    const rippleContainer = event.currentTarget.getBoundingClientRect();
    const size =
      rippleContainer.width > rippleContainer.height
        ? rippleContainer.width
        : rippleContainer.height;
    const x = event.pageX - rippleContainer.x - size / 2;
    const y = event.pageY - rippleContainer.y - size / 2;
    const newRipple = {
      x,
      y,
      size,
    };

    setRippleArray([...rippleArray, newRipple]);
  };

  return (
    <RippleContainer onPointerDown={addRipple}>
      {rippleArray.length > 0 &&
        rippleArray.map((ripple, index) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={`span ${index}`}
            style={{
              top: ripple.y,
              left: ripple.x,
              width: ripple.size,
              height: ripple.size,
            }}
          />
        ))}
    </RippleContainer>
  );
};

import { motion } from "framer-motion";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  navigationTransitionState,
  savePathLevelsAndArray,
} from "../../../utils";

import { animationVariants } from "./AnimatedRoute.animations";
import styles from "./AnimatedRoute.module.css";

const useIsBackward = () => {
  const history = useHistory();

  // Disabled, because animations doesn't work properly without it
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const location = useLocation();

  const isPop = history.action === "POP";

  useEffect(() => {
    const unlisten = history.listen((loc) => {
      savePathLevelsAndArray(loc.pathname);
    });

    return () => unlisten();
  });

  if (navigationTransitionState() === -1) {
    return true;
  }
  if (navigationTransitionState() === 1) {
    return false;
  }
  if (navigationTransitionState() === 0 && isPop) {
    return true;
  }
  return false;
};

type Props = { children: JSX.Element | JSX.Element[] };
export const AnimatedRoute = ({ children }: Props) => {
  const isBackward = useIsBackward();

  return (
    <motion.div
      className={styles.animatedRoute}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={animationVariants}
      custom={isBackward}
    >
      {children}
    </motion.div>
  );
};

import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { parse } from "date-fns";

import { mainApi } from "../../../../services/httpClients";

export const useGetAllPodcastsPerCategory = (
  categorySlug: string,
  onSuccess?: (data: AxiosResponse<any, any>) => void,
  onError?: (error: AxiosError<any, any>) => void
) =>
  useQuery<AxiosResponse<any, any>, AxiosError<any, any>>(
    ["getAllPodcastsPerCategory", categorySlug],
    () => mainApi.get("/podcasts", { params: { search: categorySlug } }),
    {
      onSuccess,
      onError,
      select: (data) => {
        const sortedPodcasts = data.data.data.sort((a: any, b: any) => {
          const aDate = parse(
            a.podcast_publication_date,
            "yyyy-MM-dd HH:mm:ss",
            new Date()
          );

          const bDate = parse(
            b.podcast_publication_date,
            "yyyy-MM-dd HH:mm:ss",
            new Date()
          );

          return bDate.getTime() - aDate.getTime();
        });

        return {
          ...data,
          data: { ...data.data, data: sortedPodcasts },
        };
      },
    }
  );

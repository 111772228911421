import styles from "./CircleLoader.module.css";

export const CircleLoader = () => (
  <span className={styles.ldsRing}>
    <span />
    <span />
    <span />
    <span />
  </span>
);

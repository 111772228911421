import Cookies from "js-cookie";

export const getTokenCookie = () => Cookies.get("token");

export const setTokenCookie = (
  token: string,
  setAsSessionCookie: boolean = false
) =>
  Cookies.set("token", token, {
    sameSite: "strict",
    expires: setAsSessionCookie ? 14 : undefined,
  });

export const removeTokenCookie = () => Cookies.remove("token");

import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { useAuthContext } from "../contexts/AuthContext";
import { getTokenCookie } from "../services/authUtils";
import { mainApi } from "../services/httpClients";

export const useValidateTokenQuery = () => {
  const { setIsUserLogged } = useAuthContext();

  return useQuery<AxiosResponse<any, any>, AxiosError<any, any>>(
    ["tokenValidate"],
    () => mainApi.post("/users/validateToken", { token: getTokenCookie() }),
    {
      onSuccess: () => setIsUserLogged(true),
      onError: () => setIsUserLogged(false),
    }
  );
};

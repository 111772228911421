export const returnPathLevel = (path: string) => {
  if (path === "/") {
    return 0;
  }
  return path.split("/").length - 1;
};

export const returnPathArray = (path: string) => {
  if (path === "/") {
    return [""];
  }
  return path.split("/");
};

export const convertPathArrayIntoString = (
  path: string[] | null,
  removeLast: boolean = false
) => {
  if (!path) {
    return "";
  }

  if (removeLast) {
    path.pop();
  }

  return path.reduce((acc, value, index) => {
    if (index === 0) {
      return "";
    }
    return acc.concat("/", value);
  }, "");
};

export const savePathLevelsAndArray = (path: string) => {
  const pathLevel = returnPathLevel(path);

  sessionStorage.setItem("prevPathLevel", sessionStorage.getItem("pathLevel")!);
  sessionStorage.setItem("pathLevel", String(pathLevel));
  sessionStorage.setItem("pathArray", JSON.stringify(returnPathArray(path)));
};

/**
 * Returns -1 if navigation is backward, 1 if forward, 0 if it persisted on save route level
 *
 * @return {*}  {(-1 | 0 | 1)}
 */
export const navigationTransitionState = (): -1 | 0 | 1 => {
  if (
    sessionStorage.getItem("prevPathLevel") &&
    sessionStorage.getItem("pathLevel")
  ) {
    if (
      Number(sessionStorage.getItem("prevPathLevel")) >
      Number(sessionStorage.getItem("pathLevel"))
    ) {
      return -1;
    }
    if (
      Number(sessionStorage.getItem("prevPathLevel")) <
      Number(sessionStorage.getItem("pathLevel"))
    ) {
      return 1;
    }

    return 0;
  }
  return 1;
};

import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";

interface Props extends RouteProps {
  redirectPathWhenLoggedIn?: string | false;
  children: JSX.Element;
}

export const ProtectedRoute = ({
  children,
  redirectPathWhenLoggedIn = false,
  ...rest
}: Props) => {
  const { isUserLogged } = useAuthContext();

  const whenUserIsLoggedIn = redirectPathWhenLoggedIn ? (
    <Redirect to={redirectPathWhenLoggedIn} />
  ) : (
    children
  );
  const whenUserIsNotLoggedIn = redirectPathWhenLoggedIn ? (
    children
  ) : (
    <Redirect to="/logowanie" />
  );

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={() => (isUserLogged ? whenUserIsLoggedIn : whenUserIsNotLoggedIn)}
    />
  );
};

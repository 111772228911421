import { AuthContextProvider } from "../../../contexts/AuthContext";

import { ErrorBoundaryWrapper } from "./ErrorBoundaryWrapper";
import { PreloadContentWrapper } from "./PreloadContentWrapper";
import { ReactQueryWrapper } from "./ReactQueryWrapper";
import { ReactRouterWrapper } from "./ReactRouterWrapper";

type Props = {
  children: JSX.Element;
};
export const MainWrapper = ({ children }: Props) => (
  <ErrorBoundaryWrapper>
    <ReactQueryWrapper>
      <ReactRouterWrapper>
        <AuthContextProvider>
          <PreloadContentWrapper>{children}</PreloadContentWrapper>
        </AuthContextProvider>
      </ReactRouterWrapper>
    </ReactQueryWrapper>
  </ErrorBoundaryWrapper>
);

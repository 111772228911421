import { useQueryClient } from "@tanstack/react-query";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { removeTokenCookie } from "../services/authUtils";

type AuthContextType = {
  isUserLogged: boolean;
  setIsUserLogged: React.Dispatch<React.SetStateAction<boolean>>;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

type Props = { children: JSX.Element };
export const AuthContextProvider = ({ children }: Props) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const [isUserLogged, setIsUserLogged] = useState(false);

  const logout = useCallback(() => {
    removeTokenCookie();
    setIsUserLogged(false);
    history.push("/logowanie");
    queryClient.clear();
  }, [queryClient]);

  const authContextMemoized: AuthContextType = useMemo(
    () => ({ isUserLogged, setIsUserLogged, logout }),
    [isUserLogged]
  );

  return (
    <AuthContext.Provider value={authContextMemoized}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext) as AuthContextType;

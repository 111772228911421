import { CircleLoader } from "../CircleLoader";

import styles from "./FullscreenLoader.module.css";

type Props = { scale?: number };
export const FullscreenLoader = ({ scale = 1 }: Props) => (
  <div
    style={{ transform: `scale(${scale})` }}
    className={styles.fullscreenLoader}
  >
    <CircleLoader />
  </div>
);

import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { mainApi } from "../../../../services/httpClients";

export const useGetAboutUsContent = () =>
  useQuery<AxiosResponse<any, any>, AxiosError<any, any>>(
    ["getAboutUsContent"],
    () => mainApi.get("/content")
  );

import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { mainApi } from "../../../../services/httpClients";

export const useGetSinglePodcast = (
  podcastId: string,
  onSuccess?: (data: AxiosResponse<any, any>) => void,
  onError?: (error: AxiosError<any, any>) => void
) =>
  useQuery<AxiosResponse<any, any>, AxiosError<any, any>>(
    ["getSinglePodcast", podcastId],
    () => mainApi.get(`/podcasts/podcast/${podcastId}`),
    {
      onSuccess,
      onError,
    }
  );

import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { mainApi } from "../../../../services/httpClients";

export const useGetContactData = (
  onSuccess?: (data: AxiosResponse<any, any>) => void,
  onError?: (error: AxiosError<any, any>) => void
) => ({
  address: useQuery<AxiosResponse<any, any>, AxiosError<any, any>>(
    ["getContactAddress"],
    () => mainApi.get("/contact"),
    {
      onSuccess,
      onError,
    }
  ),
  subjects: useQuery<AxiosResponse<any, any>, AxiosError<any, any>>(
    ["getContactSubjects"],
    () => mainApi.get("/subject"),
    {
      onSuccess,
      onError,
    }
  ),
});

import clsx from "clsx";
import parseHtml, { HTMLReactParserOptions } from "html-react-parser";

import styles from "./HtmlParser.module.css";

type Props = Partial<{
  renderAsSpan: boolean;
  replaceBrsWithSpaces: boolean;
  htmlString: string;
  options: HTMLReactParserOptions;
  style: React.CSSProperties;
  className: string;
}>;
export const HtmlParser = ({
  renderAsSpan = false,
  replaceBrsWithSpaces = false,
  htmlString = undefined,
  options = replaceBrsWithSpaces
    ? // eslint-disable-next-line react/no-unstable-nested-components
      { replace: (domNode) => domNode.type === "tag" && <>&nbsp;</> }
    : undefined,
  style = undefined,
  className,
}: Props) =>
  renderAsSpan ? (
    <span style={style} className={clsx(styles.htmlParser, className)}>
      {parseHtml(htmlString ?? "", options)}
    </span>
  ) : (
    <div style={style} className={clsx(styles.htmlParser, className)}>
      {parseHtml(htmlString ?? "", options)}
    </div>
  );
